import { is_applehealthkit_authorized, supports_apple_healthkit } from "$lib/apple_healthkit/apple_healthkit";
import type { UserDeviceSyncSettingsData } from "$lib/server/device_sync/DeviceSync";

type SyncPartnerAction = {
                            [partner: string]: {
                                deauthorize_label: string, 
                                deauthorize_url: string, 
                                authorize_label: string, 
                                authorize_url: string
                            }
                        };

class SyncDeviceState {

    private _initialized: boolean = false;
    private _user_device_sync_settings: UserDeviceSyncSettingsData = $state({});
    private _apple_healthkit_supported: boolean = $state(false);
    private _partner_actions: SyncPartnerAction =
        {
            'garmin': 
                {
                    deauthorize_label: 'Remove',
                    deauthorize_url: `/integrations/manage/garmin/deregister`,
                    authorize_label: 'Setup',
                    authorize_url: `/integrations/manage/garmin/authorize/step1`
                },
            'apple': 
                {
                    deauthorize_label: 'View',
                    deauthorize_url: `/integrations/manage/apple/authorize/step1`,
                    authorize_label: 'Setup',
                    authorize_url: `/integrations/manage/apple/authorize/step1`
                }
        };

    initialize(initial_state: UserDeviceSyncSettingsData) {

        // console.log("Before initializing state -> ");
        // $state.snapshot(this._user_device_sync_settings);
        this._user_device_sync_settings = {...initial_state};
        // console.log("Initializing state -> ", $state.snapshot(this._user_device_sync_settings));
    }

    private async init() {

        this._apple_healthkit_supported = await supports_apple_healthkit();
        
        if (this._apple_healthkit_supported) { 
            this._user_device_sync_settings['apple'] = {active: await is_applehealthkit_authorized()};
        }

        this._initialized = true;
    }

    async get_user_device_sync_settings(): Promise<UserDeviceSyncSettingsData> {

        if (this._initialized === false) {
            await this.init();
        }

        return this._user_device_sync_settings;
    }

    activate(partner: string) {

        if (this._user_device_sync_settings[partner]) {
            this._user_device_sync_settings[partner].active = true;
        } else {
            this._user_device_sync_settings[partner] = {active: true, creation_timestamp: new Date()};
        }
    }

    deactivate(partner: string) {

        if (this._user_device_sync_settings[partner]) {
            this._user_device_sync_settings[partner].active = false;
        } else {
            this._user_device_sync_settings[partner] = {active: false, creation_timestamp: new Date()};
        }
    }

    async is_apple_healthkit_supported(): Promise<boolean> {
        
        if (this._initialized === false) {
            await this.init();
        }

        return this._apple_healthkit_supported;
    }

    get partner_actions(): SyncPartnerAction {

        return this._partner_actions;
    }

    async is_device_configured(): Promise<boolean> {

        if (this._initialized === false) {
            await this.init();
        }

        return Object.values(this._user_device_sync_settings).some(entry => entry.active);
    }
}

const sync_device_state = new SyncDeviceState();
export default sync_device_state;